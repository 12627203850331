const DEVICE_ID_KEY = 'enterpriseDeviceId';

/**
 * Store a generated device id in both localstorage and a cookie.
 *
 * @param {string} deviceId
 */
export function saveDeviceId(deviceId) {
  try {
    document.cookie = `${DEVICE_ID_KEY}=${deviceId};path=/;max-age=31536000;secure;samesite=strict`;
    window.localStorage.setItem(DEVICE_ID_KEY, deviceId);
  } catch (e) { }
}

/**
 * Retrieve a previously stored deviceid from either localstorage or a cookie
 *
 * @return {string|null|undefined}
 */
export function retrieveDeviceId() {
  let deviceId;
  try {
    deviceId = window.localStorage.getItem(DEVICE_ID_KEY);
  } catch (e) { }

  if (!deviceId) {
    const cookieParts = (new RegExp(`(?:^|;)${DEVICE_ID_KEY}=([^;]+)(?:$|;)`)).exec(document.cookie);
    if (cookieParts) {
      deviceId = cookieParts[1];
    }
  }
  return deviceId;
}

/** @return string */
function getDeviceFingerprint() {
  let secretId;

  try {
    // `crypto` has ~95% support as of May 2022 ( https://caniuse.com/cryptography ). It's has more bits of entropy and is preferred if available.
    secretId = crypto.randomUUID().replaceAll('-', '');
  } catch (err) {
    secretId = Array.from({length: 32}, () => Math.floor(Math.random()*16).toString(16)).join('');
  }

  if (!secretId || secretId.length !== 32) {
    throw new Error('Secret ID not found or has invalid length. This secret is required to remember 2FA. Exiting.');
  }

  saveDeviceId(secretId);
  return secretId;
}

/** @return {!Promise<string>} */
export function calculateDeviceId() {
  return new Promise((resolve) => {
    const cachedDeviceId = retrieveDeviceId();
    if (cachedDeviceId) {
      resolve(cachedDeviceId);
      return;
    } else {
      resolve(getDeviceFingerprint());
      return;
    }
  });
}
